<template>
  <div class="SupportMenuContent">
    <a :href="`https://wa.me/+${$locale.adminUser.calling_code}${$locale.adminUser.phone}`" target="_blank" @click="modal.close()">
      <CircleButton :label="`Whatsapp`" icon="whatsapp" size="52px" />
    </a>
    <a :href="`mailto:${$locale.adminUser.email}`" @click="modal.close()">
      <CircleButton :label="`Email`" icon="envelope" size="52px" />
    </a>
    <a :href="`tel:+${$locale.adminUser.calling_code}${$locale.adminUser.phone}`" @click="modal.close()">
      <CircleButton :label="$locale['phone']" icon="phone_cirle" size="52px" />
    </a>

    <CircleButton :label="$locale['message']" icon="mf_lock" size="52px" @click="goSupport('message')" />
  </div>
</template>

<script>
import CircleButton from "../widgets/CircleButton.vue";
export default {
  components: { CircleButton },
  props: ["modal"],
  methods: {
    goSupport: function(type) {
      this.modal.close(() => {
        this.openSupport(type);
      });
    },
    openSupport: function(support) {
      const { _id, phone, email } = this.$locale.adminUser;
      if (this.$user && support === "message") {
        this.setQuery({ messages: _id });
      } else {
        this.payload({ afterLoginQuery: { messages: _id } });
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss">
.SupportMenuContent {
  padding: $mpadding;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $mpadding;
  text-align: center;
  .CircleButtonFigure {
    font-size: 85%;
  }
  .CircleButtonLabel {
    font-size: 90%;
  }
}
</style>
